import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { doctorListReducer } from "./reducers/doctorReducers";
import {
  patientCreateReducer,
  patientListReducer,
  patientVisitCreateReducer,
  patientVisitListReducer,
  patientsRecentVisitsListReducer,
  patientVisitDeleteReducer,
} from "./reducers/patientReducers";
import LoginReducer from "./redux/logins";

const reducer = combineReducers({
  loginData: LoginReducer,

  doctorList: doctorListReducer,

  patientCreate: patientCreateReducer,
  patientList: patientListReducer,

  patientVisitCreate: patientVisitCreateReducer,
  patientVisitList: patientVisitListReducer,
  patientsRecentVisitsList: patientsRecentVisitsListReducer,
  patientVisitDelete: patientVisitDeleteReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
