import moment from "moment";

export const formatDate = (date) => {
  return moment(date).format("MMM-DD-YYYY");
};

export const formatTime = (date) => {
  return moment(date).format("HH:mm:ss");
};

export const formatDOB = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
