import {
  PATIENT_CREATE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_CREATE_FAIL,
  PATIENT_CREATE_RESET,
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_FAIL,
  PATIENTVISIT_CREATE_REQUEST,
  PATIENTVISIT_CREATE_SUCCESS,
  PATIENTVISIT_CREATE_FAIL,
  PATIENTVISIT_CREATE_RESET,
  PATIENTVISIT_LIST_REQUEST,
  PATIENTVISIT_LIST_SUCCESS,
  PATIENTVISIT_LIST_FAIL,
  PATIENT_RECENT_VISIT_LIST_REQUEST,
  PATIENT_RECENT_VISIT_LIST_SUCCESS,
  PATIENT_RECENT_VISIT_LIST_FAIL,
  PATIENT_VISIT_DELETE_REQUEST,
  PATIENT_VISIT_DELETE_SUCCESS,
  PATIENT_VISIT_DELETE_FAIL,
} from "../constants/patientConstants";

export const patientCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_CREATE_REQUEST:
      return { loading: true };

    case PATIENT_CREATE_SUCCESS:
      return { loading: false, success: true };

    case PATIENT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case PATIENT_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const patientListReducer = (state = { patients: [] }, action) => {
  switch (action.type) {
    case PATIENT_LIST_REQUEST:
      return { loading: true, patients: [] };

    case PATIENT_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload,
      };

    case PATIENT_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};
export const patientVisitCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENTVISIT_CREATE_REQUEST:
      return { loading: true };

    case PATIENTVISIT_CREATE_SUCCESS:
      return { loading: false, success: true, data: action.payload };

    case PATIENTVISIT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case PATIENTVISIT_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const patientVisitListReducer = (state = { patients: [] }, action) => {
  switch (action.type) {
    case PATIENTVISIT_LIST_REQUEST:
      return { loading: true, patients: [] };

    case PATIENTVISIT_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload.data,
      };

    case PATIENTVISIT_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};

export const patientsRecentVisitsListReducer = (
  state = { visits: [] },
  action
) => {
  switch (action.type) {
    case PATIENT_RECENT_VISIT_LIST_REQUEST:
      return { loading: true, visits: [] };

    case PATIENT_RECENT_VISIT_LIST_SUCCESS:
      return {
        loading: false,
        visits: action.payload,
      };

    case PATIENT_RECENT_VISIT_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};

export const patientVisitDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_VISIT_DELETE_REQUEST:
      return { loading: true };

    case PATIENT_VISIT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case PATIENT_VISIT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
