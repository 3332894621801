// import "../../assets/css/style2.css";
import React, { useEffect, useState, useCallback } from "react";
import { formatDate, formatTime } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { listPatientRecentVisits } from "../actions/patientActions";
import ListHeading from "../components/list-record-components/list-header/listHeading";
// import TableComponent from "../components/TableComponent";
import Table from "../components/react-table-component/ReactTable";
import "rsuite/dist/rsuite.css";
import { ArrowDownward, ArrowForwardIos } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function RecentVisitsList() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const visitsList = useSelector((state) => state.patientsRecentVisitsList);
  const { error, loading, visits } = visitsList;

  console.log("Visits :: ", visits?.data);

  useEffect(() => {
    dispatch(listPatientRecentVisits(token, { page: 1 }));
  }, [dispatch, token]);

  const [searchField, setSearchField] = useState("");

  const handleSearch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  const fetchData = useCallback(({ pageSize, pageIndex, params, sortBy }) => {
    const queryParams = {
      page: pageIndex + 1,
      // sortBy: sortBy[0]?.id || "createdAt",
      // sortOrder: sortBy[0]?.desc === false ? "asc" : "desc",
      // search: params,
    };
    dispatch(listPatientRecentVisits(token, queryParams));
  }, []);

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(rowIndex)) {
        // Remove the row index if it's already expanded
        const newExpandedRows = prevExpandedRows.filter(
          (index) => index !== rowIndex
        );

        return newExpandedRows;
      } else {
        // Add the row index if it's not expanded
        const newExpandedRows = [...prevExpandedRows, rowIndex];
        return newExpandedRows;
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date of Visit",
        accessor: "timestamp",
        Cell: ({ row }) => {
          const isExpanded = expandedRows.includes(row.index);
          return (
            <div
              onClick={() => toggleRowExpansion(row.index)}
              style={{ cursor: "pointer" }}
            >
              {isExpanded ? (
                <ArrowDownward style={{ fontSize: 12, marginRight: 4 }} />
              ) : (
                <ArrowForwardIos style={{ fontSize: 12, marginRight: 4 }} />
              )}
              {formatDate(row.original.timestamp)}
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "patient.name",
      },
      {
        Header: "DOB",
        accessor: "patient.dob",
      },
      {
        Header: "MRN",
        Cell: ({ row }) => {
          const mrnValue = `MRN-${row.index + 1000}`; // generated static value for MRN
          return <div>{mrnValue}</div>;
        },
      },
      {
        Header: "Time",
        sortable: true,
        accessor: "time",
        Cell: ({ row }) => {
          return <div>{formatTime(row.values.timestamp)}</div>;
        },
      },
      {
        Header: "Setting",
        Cell: () => {
          return <div>Clinic</div>;
        },
      },
      {
        Header: "Key Notes",
        Cell: () => {
          return <div>Normal</div>;
        },
      },
      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const isExpanded = expandedRows.includes(row.index);
          return (
            <div>
              {isExpanded ? (
                <Link
                  to={`/listrecordings/${row.original.patient.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className="action-button-expanded">View Visit</button>
                </Link>
              ) : (
                <Link
                  to={`/listrecordings/${row.original.patient.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className="action-button">View Visit</button>
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    [expandedRows]
  );

  return (
    <>
      <section className="patient-list">
        <ListHeading title="Recent Visits" handleSearch={handleSearch} />
        <div>
          <button className="button btn-default rounded-pill">
            COMPARE VISITS
          </button>
          <button className="button btn-default rounded-pill">DELETE</button>
        </div>

        <Table
          columns={columns}
          data={visits?.data?.results ?? []}
          // params={searchField}
          fetchData={fetchData}
          pageCount={visits?.data?.pagination?.total_pages ?? 1}
          loading={loading}
        />
      </section>
    </>
  );
}
