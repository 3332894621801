import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "../recording2Updated/recording2.css";
import Plot from "react-plotly.js";

const HeatMapGraph = () => {
  const params = useParams();
  const token = useSelector((state) => state.loginData.token);
  const [heatmapData, setHeatmapData] = useState(null);
  const [dataHeatMap, setDataHeatMap] = useState(null);
  console.log(dataHeatMap);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/pwelch_plot/${params.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );

        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else if (response.data.message !== "error") {
          const heatmapData = response.data.data.updated_Sxx;
          setHeatmapData(heatmapData);
          setDataHeatMap(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching heatmap data:", error);
      }
    };

    if (!heatmapData) {
      fetchData();
    }
  }, [heatmapData, params.id, token]);

  const plotlyData = [
    {
      x: dataHeatMap?.time,
      y: dataHeatMap?.frequency,
      z: heatmapData,
      type: "heatmap",
      colorscale: "Jet",
      // zmin: -5,
      // zmax: 15,
      colorbar: {
        title: "Heatmap Values",
        // tickmode: "array",
        // tickvals: [-5, 0, 5, 10, 15],
        // ticktext: ["-5", "0", "5", "10", "15"],
      },
    },
  ];

  return (
    <div className="fullscreen-container">
      {heatmapData ? (
        <Plot
          data={plotlyData}
          layout={{
            autosize: true,
            xaxis: { title: "Time" },
            yaxis: { title: "Frequency" },
            margin: { t: 20, b: 40, l: 50, r: 20 },
          }}
          useResizeHandler
          style={{ width: "100%", height: "200px" }}
        />
      ) : (
        <div>Loading heatmap data...</div>
      )}
    </div>
  );
};

export default HeatMapGraph;
